import { tns } from "tiny-slider/src/tiny-slider";
require('tiny-slider/dist/tiny-slider.css');

let timer = null;
const setActive = (ss)=>{
  let cp;
  document.querySelectorAll('.tns-item').forEach(el=>{
    el.classList.remove('pry-slide-active');
    cp = el.querySelector('.card-proy');
    cp?.classList.add('preview')
  });
  clearTimeout(timer);
  timer = setTimeout(()=>{
    let slide_item = document.querySelectorAll('.tns-item')[ss.index];
    slide_item?.classList.add('pry-slide-active');
    cp = slide_item.querySelector('.card-proy');
    cp?.classList.remove('preview')
  }, 310);
}

let slider = tns({
  container: '.proyectos-slider',
  controls: false,
  nav: false,
  items: 10,
  center: true,
  slideBy: 1,
  startIndex: 1,
  autoWidth: true,
  onInit: setActive
});

slider.events.on('indexChanged', setActive);

document.querySelectorAll('.card-proy').forEach(el=>{
  el.addEventListener('click', evt=>{
    if(evt.currentTarget.classList.contains('preview')){
      evt.preventDefault();
      slider.goTo(evt.target.offsetLeft > document.querySelector('.pry-slide-active').offsetLeft ? 'next' : 'prev');
    }
  });
});
